import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MobileStepper from '@mui/material/MobileStepper';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import Avatar from '@mui/material/Avatar';
import { Container } from '@mui/system';
import tt from "../src/img/tt.webp"
import Rating from '@mui/material/Rating';
import m from "../src/img/m.png"
import n from "../src/img/n.png"
import o from "../src/img/o.png"


const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const images = [
  {
    label: 'San Francisco – Oakland Bay Bridge, United States',
    imgPath:m,
    name:"Jiya khan",
    rate:4.5,
    content:"Service is pretty good and easily access to every spot. It is better than other providers."
  },
  {
    rate:4.5,
    label: 'Birddf',
    imgPath:o,
    name:"ashish singh",
    content:"Amazing atmosphere and friendly and helpful staff. The location is just perfect, near to all main attraction.The service  is very good  and best."
  },
  





];

function Scrolller() {
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = images.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  return (
    
    <Box sx={{  flexGrow: 1 }}>
    <Container>
      <Paper
        square
        elevation={0}
        sx={{
          display: 'flex',
          alignItems: 'center',
          height: 0,
          pl: 2,
          bgcolor: 'background.default',
        }}
      >
       
      </Paper>
      <AutoPlaySwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
      >
      {images.map((step, index) => (
        
        <div key={step.label} >
         <Box sx={{display:'flex',justifyContent:'center',maxHeight:900}}>
         <Box sx={{display:'flex',alignItems:'center',flexDirection:{xs:'column',sm:'column',md:'row'},mt:2,mb:2}}>
         <Avatar
       alt="Remy Sharp"
       src={step.imgPath}
       sx={{ width:{xs:130,sm:130,md:170,lg:170},m:1, height:{xs:130,sm:130,md:170,lg:170},objectFit:'contain',background:'transparent' }}
/>
<Box sx={{marginLeft:3}}>
<Typography align='justify'  sx={{mb:2,fontWeight:'535',fontFamily: 'Roboto Slab',color:'#00337b',fontSize:{xs:14,sm:14,md:16}}}>{step.content}</Typography>

<Typography sx={{color:'##00337b',fontSize:15,fontStyle:'italic',mt:2}} >{step.name}</Typography>
<Rating name="half-rating-read" defaultValue={step.rate} precision={0.5} readOnly  size='small'/>


</Box>

         </Box>
         </Box>
         </div>
        
      ))}
      </AutoPlaySwipeableViews>
     
      </Container>
    </Box>
    
  );
}

export default Scrolller;
