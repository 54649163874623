import logo from './logo.svg';
import './App.css';
import Main from './Main';
import Test from '../src/Test'


function App() {
  return (
    <div>
    <Main/>
    </div>
  );
}

export default App;
