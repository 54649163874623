import firebase from 'firebase/compat/app';
import "firebase/compat/auth";

import 'firebase/compat/storage';
import "firebase/compat/database";

const firebaseConfig = {
  apiKey: "AIzaSyCmLdlQswdXe-7VmbLAr4vONOJS-4iWJ38",
  authDomain: "incc-3e1fa.firebaseapp.com",
  projectId: "incc-3e1fa",
  storageBucket: "incc-3e1fa.appspot.com",
  messagingSenderId: "603761238268",
  appId: "1:603761238268:web:8160ee1dd6baf6f944f779",
  measurementId: "G-0E9913P1N5"
};
firebase.initializeApp(firebaseConfig);
export const firebaseAuth = firebase.auth();

export const storage = firebase.storage();
export const database = firebase.database();

 
export default firebase;
